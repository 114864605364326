<template>
  <div class="board" >
    <ul class="boardw first">
      <li class="w80">{{$t('front.board.number')}}</li>
      <li class="w670">{{$t('front.board.title')}}</li>
      <li class="w100">{{$t('front.common.nickName')}}</li>
      <li class="w150">{{$t('front.board.regDate')}}</li>
    </ul>

    <transition-group name="fade">

      <template v-if="isView">
        <template v-for="item in noticeList" v-bind:key="item.boardIdx">
          <ul class="wblbg boardw" @click="onClickRead(item.boardIdx)" >
            <li class="w80"><img src="@/assets/img/blspeakericon.png"></li>
            <li class="w670 textleft bkc"><span class="nicon iblbg mr10">{{$t('front.board.guide')}}</span>{{item.title}}</li>
            <li class="w100"><img src="@/assets/img/kingstlogo.png" /></li>
            <li class="w150"></li>
          </ul>
        </template>

        <template v-for="item in eventList" v-bind:key="item.boardIdx">
          <ul class="wywbg boardw" @click="onClickRead(item.boardIdx)" >
            <li class="w80"><img src="@/assets/img/blspeakericon.png"></li>
            <li class="w670 textleft ywc"><span class="nicon iywbg mr10">{{$t('front.board.event')}}</span>{{item.title}}<span class="newicon rdbg ml5">N</span></li>
            <li class="w100"><img src="@/assets/img/kingstlogo.png" /></li>
            <li class="w150"></li>
          </ul>
        </template>

        <template v-for="item in list" v-bind:key="item.boardIdx">
          <ul class="boardw" @click="onClickRead(item.boardIdx)" >
            <li class="w80">{{item.boardIdx}}</li>
            <li class="w670 textleft">
              <p class="textOverflow">{{item.title}}</p>
              <span class="nsicon rdbg ml10" v-if="item.comment.length > 0">{{item.comment.length}}</span>
              <!--          <span class="nicon ignbg ml10">베팅내역</span>-->
            </li>
            <li class="w100"><span class="medal medal1"><em class="level">{{item.memLevel}}</em></span>{{item.memNick}}</li>
            <li class="w150">{{dateFormatForBoard(item.regDate)}}</li>
          </ul>
        </template>
      </template>
    </transition-group>
  </div>
  <div class="boardbtn">
    <ui-button :link="'boardWrite'" :className="'blsbtn right'" :text="$t('front.board.boardWrite')" @click="onClickWrite"/>
  </div>
  <pagination v-if="pageInfo"
      :pageNum="pageInfo.page"
      :pageSize="pageInfo.count_per_list"
      :totalCount="pageInfo.tatal_list_count"
      @goToPage="pageMove"
  />

  <board-filter @onSearch="onSearch"/>

</template>

<script>
import Pagination from '@/components/ui/Pagination'
import UiButton from '@/components/ui/UiButton'
import BoardFilter from '@/components/common/BoardFilter'

export default {
  name: 'Board',
  components: {
    BoardFilter,
    Pagination,
    UiButton
  },
  data () {
    return {
      noticeList: null,
      eventList: null,
      list: [],
      idxList: [],
      boardType: 'bbs',
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      pageType: ''
    }
  },
  created () {
    this.loadList('notice')
    this.loadList('event')
    this.loadList('normal')
  },
  computed: {
    isView () {
      return this.noticeList && this.eventList && this.list && this.noticeList.length >= 0 && this.eventList.length >= 0 && this.list.length >= 0
    }
  },
  methods: {
    getMedalClass (level) {
      if (level < 10) {
        return 'medal' + level
      } else {
        return 'medallv'
      }
    },
    getMedalClassMobile (level) {
      if (level < 10) {
        return 'level' + level
      } else {
        return 'levellv'
      }
    },
    pageMove (page) {
      this.pageInfo.page = page
      this.loadList('normal')
    },
    pageMoveMobile (page) {
      this.pageType = 'more'
      this.pageMove(page)
    },
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList('normal')
    },
    async loadList (category, type) {
      const params = {
        boardType: this.boardType,
        category: category
      }

      const searchValue = this.searchParam.searchValue
      if (category === 'normal' && searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      const result = await this.getBoardList(params)

      if (category === 'notice') {
        this.noticeList = result.list
      } else if (category === 'event') {
        this.eventList = result.list
      } else {
        if (this.pageType === 'more') {
          this.list = this.list.concat(result.list)
        } else {
          this.list = result.list
        }
        this.idxList = result.idxList
      }
    },
    onClickRead (id) {
      this.$router.push({ name: 'boardRead', params: { boardIdx: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'boardWrite' })
    }
  }
}
</script>
<style scoped>
.levelname {margin: 2px;border-radius: 16px;background: #e8f3fd;text-align: center;font-size: 12px;font-weight: bold;padding: 20px 0;}
.levelname .medal {width: 39px;height: 46px;background-size: 35px;}
.levelname .name {font-size: 16px;}
.levelname .medal .level {font-size: 16px;top: 5px;left: 0;width: 34px;}
.medal1 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal2 {background: url('~@/assets/img/medal_4.png') no-repeat;background-size: 34px;}
.medal3 {background: url('~@/assets/img/medal_5.png') no-repeat;background-size: 34px;}
.medal4 {background: url('~@/assets/img/medal_gold.png') no-repeat;background-size: 34px;}
.medal5 {background: url('~@/assets/img/medal_silver.png') no-repeat;background-size: 34px;}
.medal6 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal7 {background: url('~@/assets/img/medal_4.png') no-repeat;background-size: 34px;}
.medal8 {background: url('~@/assets/img/medal_5.png') no-repeat;background-size: 34px;}
.medal9 {background: url('~@/assets/img/medal_gold.png') no-repeat;background-size: 34px;}
.medal {width: 34px;height: 27px;margin: 0 auto;position: relative;display: inline-block;vertical-align: middle;}
.medal .level {position: absolute;top: 1px;left: 0;color: #000;font-size: 12px;width: 34px;text-align: center;line-height: 1.5em;}
.leveltxt {margin: 10px 0;font-size: 12px;}
.textOverflow {display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
